import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { AuthenticationService } from "_services";

@Injectable()
export class LogoutGuard implements CanActivate {

  constructor(
    public auth: AuthenticationService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.currentUserValue) {
      this.auth.logout();
    }
    return true;
  }
}
