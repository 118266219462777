<footer>
  <section id="footer-section" class="home-footer-section">
    <div fxLayout="column nowrap" fxLayoutAlign="center center">
      <div fxFlex="100" class="footer-logo" fxLayout="column">
        <img [src]="configuration.logo_path_inverse_color" alt="logo" height="60px" width="auto" />
      </div>
      <div fxFlex="80" class="hyperlinks" fxLayout="row" fxLayoutGap="40px" fxLayout.lt-md="column"
        fxLayoutGap.lt-md="16px">
        <a fxFlex="16" routerLink="/company" routerLinkActive="selected">{{'LAYOUT.COMPANY' | translate}}</a>
        <a fxFlex="16" routerLink="/numbers" routerLinkActive="selected">{{'LAYOUT.NUMBERS' | translate}}</a>
        <a fxFlex="16" routerLink="/sustainability" routerLinkActive="selected">{{'LAYOUT.SUSTAINABILITY' |
          translate}}</a>
        <a fxFlex="16" routerLink="/products" routerLinkActive="selected">{{'LAYOUT.PRODUCTS' | translate}}</a>
        <a fxFlex="16" routerLink="/quick-service" routerLinkActive="selected">{{'LAYOUT.QUICK_SERVICE' |
          translate}}</a>
        <a fxFlex="16" routerLink="/wool" routerLinkActive="selected">{{'LAYOUT.WOOL' | translate}}</a>
        <a fxFlex="16" routerLink="/news" routerLinkActive="selected">{{'LAYOUT.NEWS_EVENTS' | translate}}</a>
        <a fxFlex="16" routerLink="/contacts" routerLinkActive="selected">{{'LAYOUT.CONTACTS' | translate}}</a>
      </div>
      <div fxFlex="100" class="footer-group" fxLayout="row" fxLayout.lt-md="column-reverse">
        <div fxFlex="50" class="footer-copyright" fxLayout="column nowrap" fxLayoutAlign="center center">
          <div class="copyright" fxFlex="100">
            <span>2020 - {{ currentYear }} &copy; {{configuration.name}} | {{"ALL_RIGHTS_RESERVED" |
              translate}}.</span>
          </div>
          <div class="policy" fxFlex="100" class="footer-hyperlinks" fxLayout="row" fxLayoutGap="40px"
            fxLayout.lt-md="column" fxLayoutGap.lt-md="16px">
            <a routerLink="/privacy">{{'LAYOUT.PRIVACY_POLICY' | translate}}</a>
            <a routerLink="/sales">{{'LAYOUT.SALES_POLICY' | translate}}</a>
          </div>
        </div>
        <div fxFlex="50" fxLayout="column nowrap" fxLayoutAlign="center center">
          <a routerLink="/projects">
            <img src="/assets/images/projects-footer-black.png" alt="logo" height="60px" width="auto" />
          </a>
        </div>
      </div>
    </div>
  </section>
</footer>