import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnumTranslatePipe } from 'app/shared/pipes/enum-translate-pipe';
import { TimeFormatPipe } from 'app/shared/pipes/time-format.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { RelativeTimePipe } from './relative-time.pipe';


const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  TimeFormatPipe,
  EnumTranslatePipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule { }