import { Product } from "./product";
import { CartItemType } from "@helpers/enum";

export class CartItem {
    id: string;
    productCode: string;
    data?: {
        quantity: number,
        product: Product
    };
    color: string;
    meters: number;
    quantity: number;
    height: number;
    width: number;
    type: CartItemType;
}