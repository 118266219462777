import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { languageToIndex } from '@helpers/language';
import { TranslateService } from '@ngx-translate/core';
import { NewsletterService } from '@services/newsletter.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
    newsletterForm: FormGroup;
    unsubscribeForm: FormGroup;

    sendingEmail = false;
    emailSuccess: boolean = null;
    removeSubscriptionMode = false;

    get email() { return this.newsletterForm.get('email'); }
    get emailUnsubscribe() { return this.unsubscribeForm.get('email'); }
    get name() { return this.newsletterForm.get('name'); }
    get terms_accepted() { return this.newsletterForm.get('terms_accepted'); }

    constructor(
        private _loader: AppLoaderService,
        private newsletterService: NewsletterService,
        private _recaptchaV3Service: ReCaptchaV3Service,
        private translate: TranslateService,
    ) { }

    ngOnInit() {
        this.newsletterForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            name: new FormControl('', [Validators.required]),
            terms_accepted: new FormControl(false, [Validators.requiredTrue])
        });

        this.unsubscribeForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    register() {
        const newSubscription = this.newsletterForm.value;
        this.sendingEmail = true;

        this._loader.open();

        this.newsletterService.add({
            newsletterInfo: {
                name: newSubscription.name,
                email: newSubscription.email,
                language: languageToIndex(this.translate.currentLang)
            }
        }).subscribe(
            data => {
                this._loader.close();
                this.sendingEmail = false;
                this.emailSuccess = true;
                this.newsletterForm.reset();
                // this.snack.open(this._translateService.instant("NEWSLETTER_ACTIONS.REGISTER_NEWSLETTER"), 'OK', { duration: 4000 });
                setTimeout(() => {
                    this.emailSuccess = null;
                }, 8000);
            },
            _ => {
                this._loader.close();
                this.sendingEmail = false;
                this.emailSuccess = false;
                this.newsletterForm.reset();
                setTimeout(() => {
                    this.emailSuccess = null;
                }, 8000);
            }
        )
    }

    changeMode() {
        this.removeSubscriptionMode = !this.removeSubscriptionMode;
        this.newsletterForm.reset();
    }

    unsubscribe() {
        const newSubscription = this.unsubscribeForm.value;
        this.sendingEmail = true;

        this._loader.open();

        this.newsletterService.cancel(newSubscription.email).subscribe(
            data => {
                this._loader.close();
                this.sendingEmail = false;
                this.emailSuccess = true;
                this.unsubscribeForm.reset();
                // this.snack.open(this._translateService.instant("NEWSLETTER_ACTIONS.CANCEL_NEWSLETTER"), 'OK', { duration: 4000 });
                setTimeout(() => {
                    this.emailSuccess = null;
                }, 8000);
            },
            _ => {
                this._loader.close();
                this.sendingEmail = false;
                this.emailSuccess = false;
                this.unsubscribeForm.reset();
                setTimeout(() => {
                    this.emailSuccess = null;
                }, 8000);
            }
        )
    }


    validateRecaptcha(): void {
        this._recaptchaV3Service.execute('subscribeEmail')
            .subscribe((token) => {
                this.register();
            });
    }

    validateRecaptchaUnsubscribe(): void {
        this._recaptchaV3Service.execute('unsubscribeEmail')
            .subscribe((token) => {
                this.unsubscribe();
            });
    }

}
