<div class="header-top-bar">
  <div class="top-welcome-contact" fxFlex="50">
    {{"WELCOME_TO" | translate}} {{configuration.name}}
    <div>
      <span class="separator"> | </span>
      <span class="material-icons">phone</span>
      {{"CONTACTS_PAGE.TEL" | translate}}
    </div>
  </div>
  <div class="top-login-language" fxFlex="50" fxFlex.lt-sm="50" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
    <!-- Language Switcher -->
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
        <span>{{lang.name}}</span>
      </button>
    </mat-menu>
    <button class="language_button" mat-button [matMenuTriggerFor]="menu">
      <span fxFlex fxShow="true" fxHide.lt-sm="true">{{currentLang.name}}</span>
      <span fxFlex fxHide="true" fxShow.lt-sm="true">{{currentLang.shortName}}</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <!-- Link to Catalog -->
    <ng-container *ngIf="mainPage">
      <a routerLink="/catalogue" class="catalog-link">{{"CATALOGUE" | translate}}</a>
    </ng-container>
    <ng-container *ngIf="!mainPage">
      <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button user_logged">
        <span fxFlex fxShow="true" fxHide.lt-sm="true">{{currentUser.name}}</span>
        <span fxFlex fxHide="true" fxShow.lt-sm="true">Conta</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #accountMenu="matMenu">
        <!-- <button mat-menu-item [routerLink]="['/profile/overview']">
          <mat-icon>account_box</mat-icon>
          <span>{{"PROFILE" | translate}}</span>
        </button> -->
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{"SIGNOUT" | translate}}</span>
        </button>
      </mat-menu>
    </ng-container>
  </div>
</div>