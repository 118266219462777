import { Component } from '@angular/core';
import { configuration } from 'configuration/configuration';
import { egretAnimations } from "../../../../shared/animations/egret-animations";

@Component({
    selector: 'app-footer-pdf',
    templateUrl: './footer-pdf.component.html',
    styleUrls: ['./footer-pdf.component.scss'],
    animations: egretAnimations
})
export class FooterPdfComponent {
    configuration = configuration;
    constructor() { }
}