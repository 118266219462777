import { Injectable } from "@angular/core";
import { UserType } from "@models*";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@services/authentication.service";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[];
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>([]);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor(
    private _translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUserSubject.subscribe(newUser => {
      this.iconMenu = [
        {
          name: this._translateService.instant("NAVBAR.DASHBOARD"),
          type: "link",
          tooltip: "Dashboard",
          icon: "dashboard",
          state: "dashboard",
          disabled: false
        },
        {
          name: this._translateService.instant("NAVBAR.PLATFORM_USERS"),
          type: "link",
          tooltip: "Platform Users List",
          icon: "supervised_user_circle",
          state: "dashboard/admin",
          disabled: newUser?.userType != UserType.ADMIN,
          sub: [
            {
              name: this._translateService.instant("NAVBAR.ADMINS"),
              type: "link",
              icon: "verified_user",
              state: "dashboard/admin"
            },
            {
              name: this._translateService.instant("NAVBAR.COMERCIALS"),
              type: "link",
              icon: "device_hub",
              state: "dashboard/comercials"
            },
            {
              name: this._translateService.instant("NAVBAR.USERS"),
              type: "link",
              icon: "person",
              state: "dashboard/users",
            },
          ]
        },
        {
          name: this._translateService.instant("NAVBAR.PRODUCTS"),
          type: "link",
          tooltip: "Products List",
          icon: "storefront",
          state: "dashboard/products",
          disabled: newUser?.userType != UserType.ADMIN,
          sub: [
            {
              name: this._translateService.instant("NAVBAR.COLLECTIONS"),
              type: "link",
              icon: "filter_1",
              state: "dashboard/collections",
            },
            {
              name: this._translateService.instant("NAVBAR.CONCEPTS"),
              type: "link",
              icon: "filter_2",
              state: "dashboard/concepts",
            },
            {
              name: this._translateService.instant("NAVBAR.SUBCONCEPTS"),
              type: "link",
              icon: "filter_3",
              state: "dashboard/subconcepts",
            }
          ]
        },
        {
          name: this._translateService.instant("NAVBAR.PURCHASES"),
          type: "link",
          tooltip: "Purchases List",
          icon: "shopping_cart",
          state: "dashboard/purchases",
          disabled: newUser?.userType != UserType.ADMIN
        },
        {
          name: this._translateService.instant("NAVBAR.FRONTEND"),
          type: "link",
          tooltip: "Frontend Pages",
          icon: "storefront",
          state: "dashboard/news",
          disabled: newUser?.userType != UserType.ADMIN,
          sub: [
            {
              name: this._translateService.instant("NAVBAR.AGENTS"),
              type: "link",
              icon: "support_agent",
              state: "dashboard/agents",
            },
            {
              name: this._translateService.instant("NAVBAR.CERTIFICATES"),
              type: "link",
              icon: "verified_user",
              state: "dashboard/certificates",
            },
            {
              name: this._translateService.instant("NAVBAR.EVENTS"),
              type: "link",
              icon: "event",
              state: "dashboard/events",
            },
            {
              name: this._translateService.instant("NAVBAR.NEWS"),
              type: "link",
              icon: "fiber_new",
              state: "dashboard/news",
            },
            {
              name: this._translateService.instant("NAVBAR.NUMBERS"),
              type: "link",
              icon: "filter_9_plus",
              state: "dashboard/numbers",
            },
            {
              name: this._translateService.instant("NAVBAR.PROJECTS"),
              type: "link",
              icon: "rocket_launch",
              state: "dashboard/projects",
            },
            {
              name: this._translateService.instant("NAVBAR.SOCIALS"),
              type: "link",
              icon: "hub",
              state: "dashboard/socials",
            },
            {
              name: this._translateService.instant("NAVBAR.SLIDESHOW"),
              type: "link",
              icon: "slideshow",
              state: "dashboard/slideshow",
            },
            {
              name: this._translateService.instant("NAVBAR.SUSTAINABILITY"),
              type: "link",
              icon: "recycling",
              state: "dashboard/sustainability",
            },
            {
              name: this._translateService.instant("NAVBAR.TIMELINE"),
              type: "link",
              icon: "timeline",
              state: "dashboard/timeline",
            },
            {
              name: this._translateService.instant("NAVBAR.VIDEO"),
              type: "link",
              icon: "movie",
              state: "dashboard/video",
            }
          ]
        },
        {
          name: this._translateService.instant("NAVBAR.NEWSLETTER"),
          type: "link",
          tooltip: "Newsletter Subscription List",
          icon: "subtitles",
          state: "dashboard/newsletter",
          disabled: newUser?.userType != UserType.ADMIN
        },
        {
          name: this._translateService.instant('NAVBAR.STATISTICS'),
          type: "link",
          tooltip: "Dashboard",
          icon: "dashboard",
          state: "dashboard/statistics",
          disabled: false,
          sub: [
            {
              name: this._translateService.instant('NAVBAR.STATISTICS_PRODUCTS'),
              type: "link",
              icon: "storefront",
              state: "dashboard/statistics/products",
            },
            {
              name: this._translateService.instant('NAVBAR.STATISTICS_CLIENTS'),
              type: "link",
              icon: "supervised_user_circle",
              state: "dashboard/statistics/clients",
            },
          ]
        },
      ];
      this.menuItems.next(this.iconMenu);
    })
  }

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.


  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case "separator-menu":
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case "icon-menu":
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
  }
}
