import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InterceptorSkipHeader } from '_helpers/Interceptors/interceptor.helper';
import { environment } from '../environments/environment';
import { Events } from '../_models';


@Injectable({ providedIn: 'root' })
export class EventsService {
    constructor(private http: HttpClient) { }

    getAllHomepage() {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Events[]>(`${environment.apiURL}events`, { headers }).pipe(map(events => {
            return events;
        }));
    }

    getAll() {
        return this.http.get<Events[]>(`${environment.apiURL}events`).pipe(map(events => {
            return events;
        }));
    }

    getById(id: string) {
        return this.http.get<Events>(`${environment.apiURL}events/${id}`);
    }

    add(events: Events) {
        return this.http.post<Events>(`${environment.apiURL}events/`, events);
    }

    update(eventsId: string, events: Events) {
        return this.http.put<Events>(`${environment.apiURL}events/${eventsId}`, events);
    }

    uploadEventsImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}events/uploadImage/`, formData);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}events/${id}`);
    }
}