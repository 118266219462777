import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogoutGuard } from './shared/guards/logout.guard';
import { NotFoundGuard } from './shared/services/auth/routes.guard';
import { RoleGuard } from 'app/shared/guards/role.guard';
import { config } from 'config';
import { HomeLayoutComponent } from 'app/shared/components/layouts/home-layout/home-layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    data: { title: 'Home', breadcrumb: 'Home' },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
      }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    canActivate: [LogoutGuard],
    data: { title: 'Session' },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/authentication/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { title: 'Dashboard', breadcrumb: 'DASHBOARD', roles: config.authRoles.admin_comercial },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
      }
    ]
  },
  {
    path: 'catalogue',
    canActivate: [AuthGuard, RoleGuard],
    data: { title: 'Catalogue', breadcrumb: 'CATALOGUE', roles: config.authRoles.user },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/shop/shop.module').then(m => m.ShopModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

