import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Collection } from '../_models';


@Injectable({ providedIn: 'root' })
export class CollectionService {
    constructor(private http: HttpClient) { }
    private baseUrl = 'collection';

    getAllAdmin() {
        return this.http.get<Collection[]>(`${environment.apiURL}${this.baseUrl}/admin`).pipe(map(collection => {
            return collection;
        }));
    }

    getAll() {
        return this.http.get<Collection[]>(`${environment.apiURL}${this.baseUrl}`).pipe(map(collection => {
            return collection;
        }));
    }

    getById(id: string) {
        return this.http.get<Collection>(`${environment.apiURL}${this.baseUrl}/${id}`);
    }

    add(collection: Collection) {
        return this.http.post<Collection>(`${environment.apiURL}${this.baseUrl}/`, collection);
    }

    update(collectionId: string, collection: Collection) {
        return this.http.put<Collection>(`${environment.apiURL}${this.baseUrl}/${collectionId}`, collection);
    }

    delete(collectionId: string) {
        return this.http.delete<boolean>(`${environment.apiURL}${this.baseUrl}/${collectionId}`);
    }

    updateVisibility(id: string) {
        return this.http.put<Collection>(`${environment.apiURL}${this.baseUrl}/${id}/visibility`, {});
    }

    updateOrder(id: string, sortSettings) {
        return this.http.put<boolean>(`${environment.apiURL}${this.baseUrl}/${id}/sort`, { option: sortSettings.option, data: sortSettings.sorting });
    }

    updateSelfOrder(data) {
        return this.http.put<boolean>(`${environment.apiURL}${this.baseUrl}/collectionsort`, data ? [...data] : []);
    }


    uploadCollectionImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}${this.baseUrl}/uploadImage/`, formData);
    }
}