import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { configuration } from 'configuration/configuration';

@Component({
  selector: 'app-top-header-bar',
  templateUrl: './top-header-bar.component.html',
  styleUrls: ['./top-header-bar.component.scss']
})
export class TopHeaderBarComponent {
  @Input() mainPage: boolean;
  @Input() cartData: boolean;

  configuration = configuration;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/authentication']);
  }

}
