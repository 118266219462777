<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()"
    matTooltip="{{'HEADER_SECTION.TOGGLE_MENU' | translate}}">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Search form -->
  <!-- <div fxFlex fxHide.lt-sm="true" class="search-bar">
    <form class="top-search-form">
      <mat-icon role="img">search</mat-icon>
      <input autofocus="true" placeholder="Search" type="text" />
    </form>
  </div> -->

  <span fxFlex></span>
  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu">
    <!-- <span class="flag-icon {{currentLang.flag}} mr-05"></span> -->
    <span>{{currentLang.name}}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
      <!-- <span class="flag-icon mr-05 {{lang.flag}}"></span> -->
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>


  <!-- <egret-search-input-over placeholder="Country (e.g. PT)" resultPage="/search"> </egret-search-input-over> -->
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <!-- <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'"
    class="topbar-button-right">
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button> -->
  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button" style="width: auto">
    <!-- <img src="assets/images/face-7.jpg" alt="" /> -->
    <span style="margin-left: 10px;">
      {{currentUser.name}}
    </span>
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <!-- TODO <mat-chip color="primary" selectable="false" *ngIf="currentUser.userType == '0'">User</mat-chip>
  <mat-chip color="primary" selectable="false" *ngIf="currentUser.userType == '2'">Admin</mat-chip> -->

  <mat-menu #accountMenu="matMenu">
    <!-- <button mat-menu-item [routerLink]="['dashboard/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>{{'HEADER_SECTION.ACCOUNT_SETTINGS' | translate}}</span>
    </button> -->
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{'HEADER_SECTION.SIGN_OUT' | translate}}</span>
    </button>
  </mat-menu>
</mat-toolbar>