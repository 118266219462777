import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { UserType } from '@models*';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, from, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../_services';
import { InterceptorSkipHeader } from './interceptor.helper';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    envUrl = environment.apiURL;

    refreshTokenInProgress = false;
    tokenRefreshedSource = new Subject<string>();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    newToken = new BehaviorSubject(null);

    requestsList: HttpRequest<any>[] = [];

    constructor(
        private authenticationService: AuthenticationService,
        private snack: MatSnackBar,
        private _translateService: TranslateService,
        private router: Router,
        private loader: AppLoaderService
    ) { }


    refreshToken(userLogged): Observable<any> {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            return from(this.getFingerprint()).pipe(
                switchMap(fingerPrint => {
                    return this.authenticationService.refreshToken(userLogged.refreshToken, fingerPrint).pipe(
                        tap((data) => {
                            if (data) {
                                this.newToken.next(data.token);
                                this.authenticationService.updateTokens(data.token, data.refreshToken);
                            }
                            this.refreshTokenInProgress = false;
                            this.tokenRefreshedSource.next();
                            return data;
                        }),
                        catchError((error) => {
                            this.refreshTokenInProgress = false;
                            return this.logout(error);
                        }));
                }));
        }
    }


    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            return next.handle(request.clone({ headers }));
        }

        const userLogged = this.authenticationService.currentUserValue;

        if (userLogged != null && request.url.startsWith(this.envUrl)) {
            if (!this.validateToken(userLogged) && request.url.indexOf("/api/Authentication") == -1) {
                this.loader.closeAll();
                return this.refreshToken(userLogged).pipe(
                    switchMap((data) => {
                        if (data) {
                            this.newToken.next(data.token);
                        }
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }),
                    catchError((error) => {
                        return this.logout(error);
                    }));
            }

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${userLogged.token}`
                }
            });
        }

        return next.handle(request);
    }

    addAuthHeader(request) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.newToken.getValue()}`
            }
        });
    }

    validateToken(userLogged) {
        if (userLogged.token) {
            const token = JSON.parse(atob(userLogged.token.split('.')[1]));
            if (token.exp <= Date.now() / 1000) { // /1000 because miliseconds
                return false;
            }
            return true;
        }
        return false;
    }

    getFingerprint() {
        return new Promise(resolve => {
            FingerprintJS.load().then(agent => {
                agent.get().then(val => {
                    var components = val.components;
                    delete components.localStorage;
                    delete components.sessionStorage;
                    delete components.plugins;
                    delete components.fonts;
                    //Chosen by Inklusion
                    delete components.languages;
                    delete components.colorDepth;
                    delete components.deviceMemory;
                    delete components.screenResolution;
                    // delete components.availableScreenResolution;
                    delete components.hardwareConcurrency;
                    // delete components.timezoneOffset;
                    delete components.timezone;
                    delete components.indexedDB;
                    delete components.openDatabase;
                    delete components.cpuClass;
                    delete components.canvas;
                    delete components.touchSupport;
                    delete components.audio;
                    // delete components.pluginsSupport;
                    // delete components.productSub;
                    // delete components.emptyEvalLength;
                    // delete components.errorFF;
                    delete components.cookiesEnabled;

                    resolve(FingerprintJS.hashComponents(components));
                })
            })
        })
    }

    logout(error) {
        const usr = this.authenticationService.currentUserValue;
        this.snack.open(this._translateService.instant("SESSION_EXPIRED"), 'OK', { duration: 4000 })
        this.authenticationService.logout();
        if (usr?.userType == UserType.ADMIN) {
            this.router.navigate(['authentication'], { queryParams: { return: this.router.url } });
            return throwError(error);
        } else if (usr?.userType == UserType.COMERCIAL) {
            this.router.navigate(['authentication'], { queryParams: { return: this.router.url } });
            return throwError(error);
        }
        this.router.navigate(['authentication'], { queryParams: { return: this.router.url } });
        return throwError(error);
    }
}