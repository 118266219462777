import { Component } from '@angular/core';
import { configuration } from 'configuration/configuration';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  configuration = configuration;
  currentYear;
  constructor() {
    this.currentYear = new Date().getFullYear();
  }

}
