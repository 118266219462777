<section class="newsletter-section">
    <div class="container" fxLayout="column">
        <ng-container *ngIf="!removeSubscriptionMode">
            <div fxFlex="100" class="title">{{ 'HOME_PAGE_TRANSLATIONS.NEWSLETTER.TITLE' | translate}}</div>
            <div fxFlex="100" class="subtitle">{{ 'HOME_PAGE_TRANSLATIONS.NEWSLETTER.SUBTITLE' | translate}}</div>
            <div fxFlex="100" class="input" *ngIf="emailSuccess == null">
                <form class="form" [formGroup]="newsletterForm" (ngSubmit)="validateRecaptcha()" fxFlex="100"
                    fxLayout="column" fxLayoutAlign="center center">
                    <div fxFlex="100" fxLayout="row" fxLayout.lt-md="column" class="form-container" fxLayoutGap="15px">
                        <div fxFlex="50">
                            <mat-form-field class="full-width">
                                <input matInput type="name" formControlName="name"
                                    placeholder="{{'CONTACTS_PAGE.NAME' | translate}}" required>
                            </mat-form-field>

                            <small class="form-error-msg">
                                <ng-container
                                    *ngIf="name.invalid && (name.dirty || name.touched) && name.errors.required">
                                    {{'CONTACTS_PAGE.NAME_REQUIRED' | translate}}
                                </ng-container>
                            </small>
                        </div>
                        <div fxFlex="50">
                            <mat-form-field class="full-width">
                                <input matInput type="email" formControlName="email" autocomplete="email"
                                    placeholder="{{'CONTACTS_PAGE.EMAIL' | translate}}" required>
                            </mat-form-field>
                            <small class="form-error-msg">
                                <ng-container
                                    *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required">
                                    {{'CONTACTS_PAGE.EMAIL_REQUIRED' | translate}}
                                </ng-container>
                                <ng-container
                                    *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">
                                    {{'CONTACTS_PAGE.EMAIL_NOT_VALID' | translate}}
                                </ng-container>
                            </small>
                        </div>
                    </div>
                    <div>
                        <div>
                            <mat-checkbox formControlName="terms_accepted" required>
                                {{'CONTACTS_PAGE.ACCEPT_TERMS_AND_CONDITIONS' | translate}}
                                <a routerLink="/privacy" target="_blank">
                                    {{'CONTACTS_PAGE.ACCEPT_TERMS_AND_CONDITIONS_WORD' | translate}}
                                </a>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxFlex="100" class="submit-button-row">
                        <button mat-raised-button class="mat-primary full-width mb-1 newsletter-button"
                            [disabled]="newsletterForm.invalid || sendingEmail == true">
                            {{'HOME_PAGE_TRANSLATIONS.NEWSLETTER.BUTTON' | translate}}
                        </button>
                    </div>
                </form>
            </div>
            <div class="message-row" fxFlex="100">
                <span class="send_message text-green" *ngIf="!sendingEmail && emailSuccess != null && emailSuccess">
                    {{'NEWSLETTER_ACTIONS.REGISTER_NEWSLETTER' | translate}}
                </span>
                <span class="send_message text-red" *ngIf="!sendingEmail &&  emailSuccess != null && !emailSuccess">
                    {{'CONTACTS_PAGE.SUBSCRIPTION_ERROR' | translate}}
                </span>
            </div>
        </ng-container>
        <ng-container *ngIf="removeSubscriptionMode">
            <div fxFlex="100" class="title">{{ 'HOME_PAGE_TRANSLATIONS.NEWSLETTER.CANCEL_TITLE' | translate}}</div>
            <div fxFlex="100" class="subtitle">{{ 'HOME_PAGE_TRANSLATIONS.NEWSLETTER.CANCEL_SUBITLE' | translate}}</div>
            <div fxFlex="100" class="input" *ngIf="emailSuccess == null">
                <form class="form" [formGroup]="unsubscribeForm" (ngSubmit)="validateRecaptchaUnsubscribe()"
                    fxFlex="100" fxLayout="column" fxLayoutAlign="center center" *ngIf="removeSubscriptionMode">
                    <div fxFlex="100" fxLayout="column" class="form-container">
                        <mat-form-field class="full-width">
                            <input matInput type="email" formControlName="email" autocomplete="email"
                                placeholder="{{'CONTACTS_PAGE.EMAIL' | translate}}" required>
                        </mat-form-field>
                        <small class="form-error-msg">
                            <ng-container
                                *ngIf="emailUnsubscribe.invalid && (emailUnsubscribe.dirty || emailUnsubscribe.touched) && emailUnsubscribe.errors.required">
                                {{'CONTACTS_PAGE.EMAIL_REQUIRED' | translate}}
                            </ng-container>
                            <ng-container
                                *ngIf="emailUnsubscribe.invalid && (emailUnsubscribe.dirty || emailUnsubscribe.touched) && emailUnsubscribe.errors.email">
                                {{'CONTACTS_PAGE.EMAIL_NOT_VALID' | translate}}
                            </ng-container>
                        </small>
                    </div>
                    <div fxFlex="100" class="submit-button-row">
                        <button mat-raised-button class="mat-primary full-width mb-1 newsletter-button"
                            [disabled]="unsubscribeForm.invalid || sendingEmail == true">
                            {{'HOME_PAGE_TRANSLATIONS.NEWSLETTER.REMOVE_BUTTON' | translate}}
                        </button>
                    </div>
                </form>
            </div>
            <div class="message-row" fxFlex="100">
                <span class="send_message text-green" *ngIf="!sendingEmail && emailSuccess != null && emailSuccess">
                    {{"NEWSLETTER_ACTIONS.CANCEL_NEWSLETTER" | translate}}
                </span>
                <span class="send_message text-red" *ngIf="!sendingEmail &&  emailSuccess != null && !emailSuccess">
                    {{'CONTACTS_PAGE.CANCEL_SUBSCRIPTION_ERROR' | translate}}
                </span>
            </div>
        </ng-container>
        <div fxFlex="100" class="change-mode-row" *ngIf="emailSuccess == null">
            <span class="change-mode" (click)="changeMode()">
                <ng-container *ngIf="!removeSubscriptionMode">
                    {{'HOME_PAGE_TRANSLATIONS.NEWSLETTER.I_WANT_TO_REMOVE' | translate}}
                </ng-container>
                <ng-container *ngIf="removeSubscriptionMode">
                    {{'HOME_PAGE_TRANSLATIONS.NEWSLETTER.I_WANT_TO_SUBSCRIBE' | translate}}
                </ng-container>
            </span>
        </div>

    </div>
</section>