import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Newsletter } from '_models/newsletter';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class NewsletterService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Newsletter[]>(`${environment.apiURL}newsletter`).pipe(map(newsletter => {
            return newsletter;
        }));
    }

    getById(id: string) {
        return this.http.get<Newsletter>(`${environment.apiURL}newsletter/${id}`);
    }

    add(newsletter: Newsletter) {
        return this.http.post<Newsletter>(`${environment.apiURL}newsletter/`, newsletter);
    }

    update(id: string, newsletter: Newsletter): Observable<Newsletter> {
        return this.http.put<Newsletter>(`${environment.apiURL}newsletter/${id}`, newsletter);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}newsletter/${id}`);
    }

    cancel(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}newsletter/cancel/${id}`);
    }

    confirm(id: string) {
        return this.http.get<boolean>(`${environment.apiURL}newsletter/confirm/${id}`);
    }
}