import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Admin } from '../_models';
import { environment } from '../environments/environment';
import { I18nLanguage } from '@helpers/enum';

@Injectable({ providedIn: 'root' })
export class AdminService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Admin[]>(`${environment.apiURL}admin`).pipe(map(admin => {
            return admin;
        }));
    }

    getById(id: string) {
        return this.http.get<Admin>(`${environment.apiURL}admin/${id}`);
    }

    add(admin: Admin) {
        return this.http.post<Admin>(`${environment.apiURL}admin/register`, admin);
    }

    update(adminId: string, admin: Admin) {
        return this.http.put<Admin>(`${environment.apiURL}admin/${adminId}`, admin);
    }

    updateLanguage(adminId: string, language: I18nLanguage) {
        return this.http.put<Admin>(`${environment.apiURL}admin/${adminId}/language`, language);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}admin/${id}`);
    }

    setProductSeen(productId: string) {
        return this.http.put<Admin>(`${environment.apiURL}admin/${productId}/productSeen`, {});
    }
}