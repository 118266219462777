<div class="footer">
    <div class="left" fxFlex="33">
        <div>P.O BOX 157 C.C. * Quinta das Mineiras * Boidobra</div>
        <div>6201-951 Covilhã * Portugal</div>
    </div>
    <div class="center" fxFlex="33">
        <div>IVA PT: 500213348 * Reg. Comerc 773 - Covilhã</div>
        <div>Capital: 2.500.000,00 Euros</div>
    </div>
    <div class="right" fxFlex="33">
        <div>Email: {{configuration.email}} * {{configuration.url}}</div>
        <div>Tel. {{configuration.phone_number}} Fax. {{configuration.fax}}</div>
    </div>
</div>