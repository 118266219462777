import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { CSVProduct, Product } from '../_models';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProductsService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Product[]>(`${environment.apiURL}products/admin`).pipe(map(products => {
            return products;
        }));
    }

    getAllVisible() {
        return this.http.get<Product[]>(`${environment.apiURL}products`).pipe(map(products => {
            return products;
        }));
    }

    getAllBySubConcept(subconceptId: string) {
        return this.http.get<Product[]>(`${environment.apiURL}products/${subconceptId}/subconcept`);
    }

    getById(id: string) {
        return this.http.get<Product>(`${environment.apiURL}products/${id}`);
    }

    add(products: Product) {
        return this.http.post<Product>(`${environment.apiURL}products/`, products);
    }

    uploadList(products: CSVProduct) {
        return this.http.post<Product>(`${environment.apiURL}products/upload`, products);
    }

    update(productsId: string, products: Product) {
        return this.http.put<Product>(`${environment.apiURL}products/${productsId}`, products);
    }

    delete(productsId: string) {
        return this.http.delete<boolean>(`${environment.apiURL}products/${productsId}`);
    }

    uploadProductImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}products/uploadProductImage/`, formData);
    }

    updateProductIsNew(id: string) {
        return this.http.get<Product>(`${environment.apiURL}products/setProductIsNew/${id}`);
    }

    updateProductVisibility(id: string) {
        return this.http.put<Product>(`${environment.apiURL}products/${id}/visibility`, {});
    }
}