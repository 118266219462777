import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserType } from '@models*';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../_services';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private snack: MatSnackBar,
        private _translateService: TranslateService,
        private router: Router,
        private loader: AppLoaderService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                const error = err?.error?.message || err?.statusText;
                this.loader.closeAll()
                if (err.status === 401) {
                    return this.logout(error);
                }
                if (err.status === 401) {
                    this.snack.open(this._translateService.instant('PERMISSION_DENIED'), 'OK', { duration: 4000 })
                } else if (err.error?.message) {
                    this.snack.open(this._translateService.instant("ERROR_OCURRED") + " \n " + err.error.message, 'OK', { duration: 4000 })
                } else {
                    this.snack.open(this._translateService.instant("ERROR_OCURRED"), 'OK', { duration: 4000 })
                }

                return throwError(error);
            }));
    }

    logout(error) {
        const usr = this.authenticationService.currentUserValue;
        this.snack.open(this._translateService.instant("SESSION_EXPIRED"), 'OK', { duration: 4000 })
        this.authenticationService.logout();
        if (usr?.userType == UserType.ADMIN) {
            this.router.navigate(['authentication'], { queryParams: { return: this.router.url } });
            return throwError(error);
        } else if (usr?.userType == UserType.COMERCIAL) {
            this.router.navigate(['authentication'], { queryParams: { return: this.router.url } });
            return throwError(error);
        }
        this.router.navigate(['authentication'], { queryParams: { return: this.router.url } });
        return throwError(error);
    }
}