import { KeyValue } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CartItemType } from '@helpers/enum';
import { CartItem, Purchase } from '_models';

@Component({
  selector: 'app-purchases-pdf',
  templateUrl: './purchase-pdf.component.html',
  styleUrls: ['./purchase-pdf.component.scss'],
})
export class PurchasesPdfComponent implements AfterViewChecked {
  @ViewChild('pdf') pdf: ElementRef;
  @Input() purchase: Purchase = null;

  public numberArticles: number = 0;
  public maxArticlesPerPage: number = 39;
  public maxItemsPerLine: number = 11;
  public maxItemsPerPage: number = 30;

  public totalPagesArticles: number = 0;
  public totalPagesSamples: number = 0;
  public collections = [];
  public loaded: boolean = false;

  public totalPages = {};

  constructor(
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    var currentPage = 1;
    this.collections = [];
    var collectionsIds = [];
    for (var i = 0; i < this.purchase.items.length; i++) {
      if (collectionsIds.indexOf(this.purchase.items[i].data.product.concept.collectionId) < 0) {
        collectionsIds.push(this.purchase.items[i].data.product.concept.collectionId);
        // this.collections.push(this.purchase.items[i].data.product.concept.collection);
        this.collections.push({
          info: this.purchase.items[i].data.product.concept.collection,
          samples: this.getGroupedArticles(this.purchase.items[i].data.product.concept.collectionId),
          catalogues: this.getGroupedItems(this.purchase.items[i].data.product.concept.collectionId),
        });
        this.collections[this.collections.length - 1].pages = {};
        if (this.collections[this.collections.length - 1].samples.length > 0) {
          this.collections[this.collections.length - 1].pages.samples = currentPage;
          currentPage++;
        }
        if (this.collections[this.collections.length - 1].catalogues.length > 0) {
          this.collections[this.collections.length - 1].pages.catalogues = currentPage;
          currentPage++;
        }
      }
    }
    this.loaded = true;
  }

  getTotalNumberPages() {
    var pages = 0;
    if (Object.keys(this.totalPages).length) {
      Object.keys(this.totalPages).forEach(key => {
        pages += this.totalPages[key]['articles'];
        pages += this.totalPages[key]['samples'];
      });
    }
    return pages;
  }

  setTotalNumberPages(collectionId, section, numberPages) {
    if (this.totalPages[collectionId] == null) {
      this.totalPages[collectionId] = [];
    }
    this.totalPages[collectionId][section] = numberPages;
  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add '' to the class.
    this.cd.detectChanges();
  }


  getSamples(items: CartItem[]) {
    return items.filter(indv => indv.type == CartItemType.SAMPLE);
  }

  getCoupons(items: CartItem[]) {
    return items.filter(indv => indv.type == CartItemType.COUPON);
  }


  getGroupedArticles(collectionId) {
    var articles = this.purchase.items.filter(item => item.data.product.concept.collectionId == collectionId && item.type == CartItemType.CATALOGUE);
    articles = this.groupBy(articles, "productCode");

    var groupedArticles = [];
    var lastKey = 0;

    var numberOfLines = 0;
    for (let key of Object.keys(articles)) {
      numberOfLines += Math.ceil(articles[key].length / this.maxItemsPerLine);
      if (numberOfLines > this.maxArticlesPerPage) {
        lastKey++;
        numberOfLines = 0;
      }
      if (groupedArticles[lastKey] === undefined) {
        groupedArticles[lastKey] = [];
      }
      groupedArticles[lastKey].push(articles[key]);
    }


    this.totalPagesArticles = groupedArticles.length;
    this.setTotalNumberPages(collectionId, "articles", groupedArticles.length);

    return groupedArticles;
  }

  getGroupedItems(collectionId) {
    var samples = this.purchase.items.filter(item => item.data.product.concept.collectionId == collectionId && item.type == CartItemType.SAMPLE);
    var coupons = this.purchase.items.filter(item => item.data.product.concept.collectionId == collectionId && item.type == CartItemType.COUPON);

    var groupedItems = [];
    var totalPages = 0;

    if (coupons.length + samples.length > this.maxItemsPerPage) {
      var i = 0;
      for (let item of samples) {
        var count = Math.floor(i / this.maxItemsPerPage);
        if (typeof groupedItems[count] === 'undefined') {
          var x = {
            'samples': [],
            'coupons': [],
          }
          groupedItems.push(x);
          totalPages++;
        }
        groupedItems[count]['samples'].push(item);
        i++;
      }
      for (let item of coupons) {
        var count = Math.floor(i / this.maxItemsPerPage);
        if (typeof groupedItems[count] === 'undefined') {
          var x = {
            'samples': [],
            'coupons': [],
          }
          groupedItems.push(x);
          totalPages++;
        }
        groupedItems[count]['coupons'].push(item);
        i++;
      }
    } else {
      if (samples.length > 0 || coupons.length > 0) {
        groupedItems.push({
          'samples': samples,
          'coupons': coupons,
        });
        totalPages++;
      }
    }

    // this.totalPagesSamples = totalPages;
    this.setTotalNumberPages(collectionId, "samples", totalPages);

    return groupedItems;
  }


  groupBy(array: CartItem[], key): CartItem[] {
    return array.reduce(function (a, e) {
      // GROUP BY estimated key (estKey), well, may be a just plain key
      // a -- Accumulator result object
      // e -- sequentally checked Element, the Element that is tested just at this itaration

      // new grouping name may be calculated, but must be based on real value of real field
      let estKey = (e[key]);
      const aux = estKey.split("_");
      if (aux.length == 1) {
        estKey = 0;
      } else {
        estKey = aux[0];
      }

      (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
      return a;
    }, []);
  }

  convertNumberToArray(numberElements, numberOfItemsAlreadyPopulated) {
    if (numberElements > this.maxItemsPerLine) {
      numberElements = this.maxItemsPerLine;
    }
    return [].constructor(numberElements).fill(0).map(({ }, i) => i + numberOfItemsAlreadyPopulated)
  }

  calculateElements(articleGroup, startingValue) {
    var numberOfItemsAlreadyPopulated = startingValue * this.maxItemsPerLine;
    return this.convertNumberToArray(articleGroup.length - numberOfItemsAlreadyPopulated, numberOfItemsAlreadyPopulated)
  }

  calculateMissingElements(articleGroup, startingValue) {
    var numberOfItemsAlreadyPopulated = this.calculateElements(articleGroup, startingValue);
    if (numberOfItemsAlreadyPopulated.length == this.maxItemsPerLine) {
      return this.convertNumberToArray(0, 0);
    }

    var numberRows = Math.ceil(articleGroup.length / this.maxItemsPerLine);
    return this.convertNumberToArray(numberRows * this.maxItemsPerLine - articleGroup.length, 0)
  }

  calculateNumberLinesPerArticle(articleGroup) {
    return [].constructor(Math.ceil(articleGroup.length / this.maxItemsPerLine)).fill(0).map(({ }, i) => i)
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
}