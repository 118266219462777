import { UserLoginDuration } from "_helpers/enum";
import { AuthUser } from "./authUser";

export class User extends AuthUser {
    organization: string;
    lastLogin: Date;
    devices: Devices[];
    loginToken: string;
    loginLimit: UserLoginDuration;
    isBlocked: boolean;
    isActive: boolean;
    dateLimitLogin: string;
    clientNumber: string;
    buyerName: string;
    marketResp: string;
    marketEmailResp: string;
}

export class Devices {
    id: string;
    code: string;
    userIp: string;
    expiration: string;
    active: boolean;
}