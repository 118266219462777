import { ProductSortingTypes } from "@helpers/enum";

export class SubConcept {
    public id: string;
    public conceptId: string;
    public name: string;
    public code: string;
    public description?: string;
    public imageUrl: string;
    public footerImageUrl: string;
    public conceptName?: string;
    public productsNumber?: number;
    public sortSettings: ProductSortingTypes;
    public orderNumber: number;
}