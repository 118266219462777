import { Component, ElementRef, Input } from '@angular/core';
import { Purchase } from '@models*';
import { configuration } from 'configuration/configuration';
import { egretAnimations } from "../../../../shared/animations/egret-animations";

@Component({
    selector: 'app-header-pdf',
    templateUrl: './header-pdf.component.html',
    styleUrls: ['./header-pdf.component.scss'],
    animations: egretAnimations
})
export class HeaderPdfComponent {
    @Input() purchase: Purchase;
    @Input() currentPage: number;
    @Input() totalPages: number;
    @Input() collectionName: string;

    configuration = configuration;

    constructor(
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.style.setProperty('--main-color', configuration.css.mainColor);
    }
}